<template>
    <div class="register">
        <div class="main">
            <el-card class="main_shadow" shadow="always">
                <div class="register-left">
                    <div class="left-title ml-5 mt-4">修改密码</div>
                    <div class="left-form">
                        <el-form :label-position="labelPosition" label-width="110px" :model="user" ref="user"
                                 :rules="dataRule">
                            <el-form-item label="手机号" prop="tel">
                                <el-input placeholder="请输入手机号码" v-model="user.tel"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码" prop="check">
                                <div style="flex:1;">
                                    <el-input placeholder="请输入短信验证码" v-model="user.check"
                                              style="width:55%;margin-right:10px;"></el-input>
                                    <el-button slot="append" v-if="disabledgoCaptcha"
                                               @click="goCaptcha" style="width:40%;text-align:center">发送验证码
                                    </el-button>
                                    <el-button slot="append" v-if="!disabledgoCaptcha"
                                               style="width:40%;text-align:center">{{timegoCaptcha}}后重试
                                    </el-button>
                                </div>
                            </el-form-item>
                            <el-form-item label="修改密码" prop="password">
                                <el-input placeholder="请输入修改密码" v-model="user.password" type="password"></el-input>
                            </el-form-item>
                            <el-form-item label="再次输入密码" prop="passwordAgain">
                                <el-input placeholder="请再次输入密码" v-model="passwordAgain" type="password"></el-input>
                            </el-form-item>
                            <el-button type="primary" class="form-btn" @click="regist()">确认</el-button>
                        </el-form>
                    </div>
                </div>
                <div class="register-right" align="center">
                    <div class="">
                        <svg-icon name="warning" style="color:#e34545; width:50px;height:50px;"></svg-icon>
                    </div>
                    <div class="right-already">
                        <span>已有账号？</span>
                        <router-link to="/login">登录</router-link>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'ForgotPassword',
        data() {
            //密码的校验方法
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'))
                } else if (!this.pwpattent(value)) {
                    callback(new Error("密码必须为8~16位非空字符，必须包含大小写字母、数字、不允许特殊字符空格"))
                } else {
                    callback()
                }
            };
            return {
                timegoCaptcha: 30,
                disabledgoCaptcha: true,
                labelPosition: 'left',
                user: {
                    tel: null,//手机号
                    check: null,//验证码
                    memberid: null, //姓名、手机号
                    password: null, //密码
                },
                passwordAgain: null,//再次输入密码
                dataRule: {
                    password: [
                        {required: true, validator: validatePass, trigger: 'blur',}
                    ], //密码赋值
                    // passwordAgain: [
                    //     {required: true, message: '再次输入的密码不能为空', trigger: 'blur'}
                    // ], //再次输入密码
                },
                timer: ''
            }
        },
        mounted() {
            // console.log(this.getUser())
        },
        activated() {
            this.user.password=""//密码
            this.user.tel=""//手机号
            this.user.check=""//验证码
            this.passwordAgain= ""//再次输入密码
        },
        methods: {
            ...mapGetters(["getUser"]),
            //检测密码
            pwpattent(val) {
                if (!/^[(0-9),(0-9A-Za-z)]{8,16}$/.test(val)) {
                    return false
                } else {
                    return true
                }
            },
            changePassword(user) {
                // this.user.memberid = this.getUser().memberid;
                if (this.user.password != this.passwordAgain) {
                    this.$message({
                        message: '密码不一致,请重新输入密码',
                        type: 'error',
                        showClose: 'true'
                    })
                    return false;
                }
                this.$refs[user].validate((valid) => {
                    if (valid) {
                        this.$post('/tmemberinfo/changePassword', this.user).then((data) => {
                            if (data.code == 0) {
                                this.$message({
                                    message: '密码修改成功',
                                    type: 'success',
                                    showClose: 'true'
                                })
                                this.timer = setInterval(() => {
                                    //跳转的页面
                                    this.$router.push({path: "/login"});
                                }, 3000);
                            } else {
                                this.$message({
                                    message: data.msg,
                                    type: 'error',
                                    showClose: 'true'
                                })
                            }
                        })
                    }
                });
            },
            //点击验证显示弹出框事件
            goCaptcha() {
                // this.disabledgoCaptcha = false
                // this.dialogVisiblecaptcha = true; // 滑动验证
                if (this.user.tel == null || this.user.tel == '') {
                    this.$message({
                        message: '请输入手机号',
                        type: 'error',
                        showClose: 'true'
                    })
                    return false
                }
                if (!this.interval) {
                    this.$post('/shoppingui/alimessage2', this.user).then((data) => {
                        if (data.code == '0') {
                            if (data.code == '0') {
                                // this.$router.push({path: "/"});
                            } else {
                                this.$message({
                                    message: data.message,
                                    type: 'error',
                                    showClose: 'true'
                                })
                            }
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                    this.disabledgoCaptcha = false;
                  this.timegoCaptcha = 30;
                    this.interval = setInterval(() => {
                        if (this.timegoCaptcha > 0 && this.timegoCaptcha <= 30) {
                            this.timegoCaptcha--;
                        } else {
                            this.timegoCaptcha = 60
                            this.disabledgoCaptcha = true;
                            clearInterval(this.interval);
                            this.interval = null;
                        }
                    }, 1000);
                } else {

                    // this.disabledgoCaptcha = true;
                }

            },
            regist() {
                if (this.user.password != this.passwordAgain) {
                    this.$message({
                        message: '密码不一致,请重新输入密码',
                        type: 'error',
                        showClose: 'true'
                    })
                    return false;
                }
                this.$post('/shoppingui/alogin/tmemberinfo/changePassword', this.user).then((data) => {

                    if (data.message == "修改成功") {
                        this.$message({
                            message: '密码修改成功',
                            type: 'success'
                        });
                        this.$router.push({path: "/login"});
                    } else {
                        this.$message({
                            message: data.message,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    }
</script>

<style scoped>
    @import "../assets/css/index.css";
    @import "../assets/css/page/forgetPassword.scss";
</style>
